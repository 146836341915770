import { electronicExpressWaybillService, printerService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ElectronicExpressWaybillTemplate, PayType } from '@/resource/model';
import { translation, messageError } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

type SaveElectronicExpressWaybill = Partial<
  Omit<ElectronicExpressWaybillTemplate, 'printName' | 'id' | 'expressCompanyName' | 'expressCompanyId'>
>;

@Component({
  components: {}
})
export default class AddElectronicExpressWaybill extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public electronicExpressWaybill!: ElectronicExpressWaybillTemplate | null;

  public form: SaveElectronicExpressWaybill = {
    templateId: undefined,
    com: '',
    defaultFlag: 1,
    name: '',
    net: '',
    partnerId: '',
    partnerKey: '',
    payType: undefined,
    printId: undefined,
    partnerSecret: ''
  };

  public formRules: { [p in keyof SaveElectronicExpressWaybill]: Array<object> } = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          electronicExpressWaybillService
            .checkNameRepeat(value, this.electronicExpressWaybill?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.namePepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    com: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.selectExpressCompany')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    templateId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.selectTemplate')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    payType: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.selectPaymentMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    printId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.selectPrinter')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  /**
   * 快递公司
   */
  public expressCompanys: Array<{ id: number; name: string; code: string }> = [];

  /**
   * 模板样式
   */
  public templateStyles: Array<{ id: number; name: string }> = [];

  /**
   * 打印机列表
   */
  public printers: Array<{ id: number; name: string }> = [];

  /**
   * 支付方式
   */
  public paymentModes: Array<{ key: string; value: PayType }> = [
    {
      key: 'electronicExpressWaybill.deliveryFeePrepaid',
      value: 'SHIPPER'
    },
    {
      key: 'electronicExpressWaybill.deliveryFeeToBeCollected',
      value: 'CONSIGNEE'
    },
    {
      key: 'electronicExpressWaybill.deliveryFeeMonthly',
      value: 'MONTHLY'
    }
  ];

  public get isMonthly(): boolean {
    return this.form.payType === 'MONTHLY';
  }

  public created(): void {
    this.getExpressCompanys();
    this.getPrinter();
    if (!this.electronicExpressWaybill) {
      this.title = 'electronicExpressWaybill.add';
      return;
    }
    this.getExpressTemplateByCompany(this.electronicExpressWaybill.expressCompanyId);
    this.title = 'electronicExpressWaybill.edit';
    Object.assign(this.form, this.electronicExpressWaybill);
  }

  public submit(): void {
    if (this.form.payType === 'MONTHLY') {
      if (!this.form.partnerId) {
        Message.error(translation('electronicExpressWaybill.inputAccount'));
        return;
      }
    }
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (!this.electronicExpressWaybill) {
          await electronicExpressWaybillService.post(this.form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await electronicExpressWaybillService.put(this.form);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public getExpressCompanys(): void {
    electronicExpressWaybillService
      .getExpressCompanys()
      .then(res => {
        this.expressCompanys = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public companyChanged(code: string): void {
    const expressCompany = this.expressCompanys.find(x => x.code === code);
    this.getExpressTemplateByCompany(expressCompany!.id);
  }

  /**
   * 获取模板样式
   * @param companyId 快递公司id
   */
  public getExpressTemplateByCompany(companyId: number): void {
    electronicExpressWaybillService
      .getExpressTemplateByCompany(companyId)
      .then(res => {
        this.templateStyles = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public getPrinter(): void {
    printerService
      .getAllPrinter()
      .then(res => {
        this.printers = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
