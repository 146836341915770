import { Paging } from '@/api/base';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class PagingMixin extends Vue {
  public paging: Paging = {
    currentPage: 1,
    showCount: 50
  };

  public totalData = 0;
}
