import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import AddElectronicExpressWaybill from './add-electronic-express-waybill/add-electronic-express-waybill.vue';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { dateFormat, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElectronicExpressWaybillTemplate } from '@/resource/model';
import { electronicExpressWaybillService } from '@/api';

@Component({
  components: { AddElectronicExpressWaybill }
})
export default class ElectronicExpressWaybill extends mixins(PagingMixin) {
  public tableOptions: OsTableOption<ElectronicExpressWaybillTemplate> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };

  public queryOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'system:express:e:template:save',
      handleClick: this.openDialog
    }
  ];

  public columnOptions: Array<OsTableColumn<ElectronicExpressWaybillTemplate>> = [
    {
      prop: 'name',
      label: 'electronicExpressWaybill.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'expressCompanyName',
      label: 'electronicExpressWaybill.expressCompany',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'printName',
      label: 'electronicExpressWaybill.printer',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'defaultFlag',
      label: 'electronicExpressWaybill.isDefault',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ElectronicExpressWaybillTemplate).defaultFlag === 2 ? '是' : '否';
      }
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return dateFormat((rowData as ElectronicExpressWaybillTemplate).createTime);
      }
    }
  ];

  public rowOperationOptions: RowOperation<ElectronicExpressWaybillTemplate> = {
    fixed: 'right',
    width: '200px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:express:e:template:edit',
        handleClick: (rowData: ElectronicExpressWaybillTemplate): void => {
          this.openDialog(rowData);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'system:express:e:template:delete',
        handleClick: (rowData: ElectronicExpressWaybillTemplate): void => {
          this.delete(rowData.id);
        }
      }
    ]
  };

  public queryForm = {
    keywords: ''
  };

  public electronicExpressWaybill: ElectronicExpressWaybillTemplate | null = null;

  public addDialogVisible = false;

  public created(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.loadData();
  }

  public loadData(): void {
    this.tableOptions.loading = true;
    electronicExpressWaybillService
      .getList(this.queryForm)
      .then(res => {
        this.tableOptions.data = res.data ?? [];
        this.totalData = res.total ?? 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public editSuccess(data: ElectronicExpressWaybillTemplate): void {
    const findItem = this.tableOptions.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public closeAddDialog(): void {
    this.addDialogVisible = false;
    this.electronicExpressWaybill = null;
  }

  private openDialog(electronicExpressWaybill?: ElectronicExpressWaybillTemplate): void {
    this.electronicExpressWaybill = electronicExpressWaybill ?? null;
    this.addDialogVisible = true;
  }

  private delete(id: number): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          this.tableOptions.loading = true;
          await electronicExpressWaybillService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.tableOptions.loading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
}
